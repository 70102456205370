<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter') }}
                </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="item_id">{{$t('product')}}</label>
                                <div class="d-flex search-item-group">
                                    <select name="" v-model="f_by" class="custom-select" style="width: 100px;">
                                        <option value="name" selected>{{$t('items.name')}}</option>
                                        <option value="sku_code">{{$t('items.sku_code')}}</option>
                                    </select>
                                    <multiselect v-model="item"
                                                 id="item_id"
                                                 :placeholder="$t('product')"
                                                 :label="f_by?f_by:'sku_code'"
                                                 track-by="id"
                                                 :options="items"
                                                 :multiple="false"
                                                 :taggable="false"
                                                 :show-labels="false"
                                                 :show-no-options="false"
                                                 :show-no-results="false"
                                                 :internal-search="false"
                                                 @search-change="getItems(f_by, $event)">
                                    </multiselect>
                                </div>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="invoice">{{$t('invoice')}}</label>
                                <select name="" id="invoice" v-model="filters.invoice" class="custom-select">
                                    <option value="SalesInvoice">{{ $t('daily_product_sales.SalesInvoice') }}</option>
                                    <option value="SalesInvoiceRefund">{{ $t('daily_product_sales.SalesInvoiceRefund') }}</option>
                                </select>
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label>{{$t('items.category')}}</label>
                                <treeselect
                                        :options="categories"
                                        :load-options="loadOptions"
                                        :multiple="false"
                                        :value="filters.category_id"
                                        @input="updateValue"
                                        :searchable="true"
                                        :show-count="true"
                                        :no-children-text="$t('No_sub_options')"
                                        :no-options-text="$t('No_options_available')"
                                        :no-results-text="$t('No_results_found')"
                                        :placeholder="$t('Select')">
                                </treeselect>
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label for="warehouse">{{$t('stocks_report.warehouse')}}</label>
                                <!-- <select name="" id="warehouse" v-model="filters.inventory_id" type="text" class="custom-select">
                                    <option value="">{{$t('all')}}</option>
                                    <option v-for="(row, index) in inventories" :value="row.id" :key="'inv'+index">{{ row.name }}</option>
                                </select> -->
                                <multiselect v-model="inventory"
                                             :placeholder="$t('stocks_report.warehouse')"
                                             label="name"
                                             track-by="id"
                                             :options="inventories"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false">
                                </multiselect>
                            </div>


                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label for="brand">{{$t('brand')}}</label>
                                <multiselect id="brand" v-model="brand_id"
                                             :placeholder="$t('items.brand')"
                                             label="name" track-by="id"
                                             :options="brands"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label for="customer">{{$t('customer')}}</label>
                                <multiselect id="customer" v-model="customer"
                                             :placeholder="$t('customer')"
                                             label="name"
                                             track-by="id"
                                             :options="customers"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getCustomers($event)">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label for="staff">{{$t('staff')}}</label>
                                <multiselect id="staff" v-model="employee"
                                             :placeholder="$t('employee')"
                                             label="full_name" track-by="id"
                                             :options="employees"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getEmployees($event)">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label for="from_date">{{$t('inventory_transaction_report.from_date')}}</label>
                                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label for="to_date">{{$t('inventory_transaction_report.to_date')}}</label>
                                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label>{{$t('currency')}}</label>
                                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                                </select>
                            </div>
                            <div class="form-group d-inline-flex col-md-4 mt-10 justify-content-center p-2">

                                <b-form-checkbox
                                        id="is_show_draft"
                                        v-model="filters.is_show_draft"
                                        name="is_show_draft"
                                >
                                    {{$t('daily_product_sales.show_draft')}}
                                </b-form-checkbox>
                            </div>

                            <div class="form-group d-inline-flex col-md-4 mt-10">
                                <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{$t('display_report')}}</b-button>
                                <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <!--begin::customer-->
        <div class="card card-custom mt-5">

            <div class="card-body">

                <!-- Filter -->
                <div class="form-group col-md-3">
                    <label>{{$t('date')}}</label>
                    <select name="" id="" v-model="filter_by" class="custom-select" @change="selectFilter()">
<!--                        <option :value="null">{{$t('all')}}</option>-->
                        <option value="daily">{{$t('daily')}}</option>
                        <option value="weekly">{{$t('weekly')}}</option>
                        <option value="monthly">{{$t('monthly')}}</option>
                        <option value="yearly">{{$t('yearly')}}</option>
                    </select>

                </div>
                <div class="col-md-12 mb-5">
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <b-tabs content-class="mt-3">
                            <b-tab :title="$t('summery')" active>
                                <div class="row">
                                    <div class="col-md-12 mt-10">
                                        <DailyProductSalesChart :data-line-invoice="daily_sales" :currency-name="currency_name"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 mt-10" id="summaryTableTitle">
                                        <h4 class="text-center">{{ $t('daily_product_sales.daily_product_sales')}}</h4>
                                        <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} 
                                            {{filters.from_date}}</span> <span v-if="filters.to_date">{{ $t('to') }} {{filters.to_date}}</span>
                                        </h5>
                                    </div>
                                    <div class="col-12 mt-10 d-flex justify-content-end">
                                        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                            <download-excel
                                                    class="dropdown-item"
                                                    :fetch="dataReport"
                                                    :fields="json_fields"
                                                    :name="$t('daily_product_sales.daily_product_sales')+'.xls'">
                                                <i class="la la-file-excel"></i>{{$t('excel')}}
                                            </download-excel>
                                            <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                                                <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                            </button>
                                            <button class="dropdown-item" @click="printData('summaryTable', 'pdf')">
                                                <span><i class="fa fa-file-pdf"></i> <span>{{$t('export_pdf')}}</span></span>
                                            </button>
                                        </b-dropdown>
                                    </div>
                                    <div class="col-12 mt-10">
                                        <div class="table-responsive">
                                            <table class="table table-bordered text-center" id="summaryTable">
                                                <thead>
                                                <tr>
                                                    <th>{{$t('daily_product_sales.day')}}</th>
                                                    <th>{{$t('daily_product_sales.qty')}}</th>
                                                    <th>{{$t('daily_product_sales.discount')}}</th>
                                                    <th>{{$t('daily_product_sales.price')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(row, index) in data" :key="index">
                                                    <td>{{row.label}}</td>
                                                    <td>{{row.qty}}</td>
                                                    <td>{{row.discount_val}}</td>
                                                    <td>{{row.subtotal_price}}</td>
                                                </tr>
                                                </tbody>
                                                <tbody>
                                                <tr v-if="dataTotal">
                                                    <td>{{$t('total')}}</td>
                                                    <td>{{dataTotal.qty}}</td>
                                                    <td>{{dataTotal.discount_val}}</td>
                                                    <td>{{dataTotal.subtotal_price}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </b-tab>

                            <b-tab :title="$t('details')">
                                <div class="row">
                                    <div class="col-md-12 mt-10">
                                        <DailyProductSalesChart :data-line-invoice="daily_sales" :currency-name="currency_name"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 mt-10" id="summaryTableTitle">
                                        <h4 class="text-center">{{ $t('daily_product_sales.daily_product_sales_details')}}</h4>
                                        <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} 
                                            {{filters.from_date}}</span> <span v-if="filters.to_date">{{ $t('to') }} {{filters.to_date}}</span>
                                        </h5>
                                    </div>
                                    <div class="col-12 mt-10 d-flex justify-content-end">
                                        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                            <!-- <download-excel
                                                    class="dropdown-item"
                                                    :fetch="dataReportDetails"
                                                    :fields="json_fields_details"
                                                    :name="$t('daily_product_sales.daily_product_sales_details')+'.xls'">
                                                <i class="la la-file-excel"></i>{{$t('excel')}}
                                            </download-excel> -->
                                            <button class="dropdown-item" @click="convertTableToExcelReport">
                                                <i class="la la-file-excel"></i>{{$t('excel')}}
                                            </button>
                                            <button class="dropdown-item" @click="printData('summaryTableDetails', 'print')">
                                                <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                            </button>
                                            <button class="dropdown-item" @click="printData('summaryTableDetails', 'pdf')">
                                                <span><i class="fa fa-file-pdf"></i> <span>{{$t('export_pdf')}}</span></span>
                                            </button>
                                        </b-dropdown>
                                    </div>
                                    <div class="col-12 mt-10">
                                        <div class="table-responsive">
                                            <table class="table table-bordered" id="summaryTableDetails">
                                                <thead>
                                                <tr id="myRow">
                                                    <th>{{$t('daily_product_sales.number')}}</th>
                                                    <th>{{$t('name')}}</th>
                                                    <th>{{$t('daily_product_sales.proudect_code')}}</th>
                                                    <th>{{$t('daily_product_sales.sold_by')}}</th>
                                                    <th>{{$t('daily_product_sales.invoice_no')}}</th>
                                                    <th>{{$t('daily_product_sales.client')}}</th>
                                                    <th>{{$t('daily_product_sales.unit_price')}}</th>
                                                    <th>{{$t('daily_product_sales.qty')}}</th>
                                                    <th>{{$t('daily_product_sales.discount')}}</th>
                                                    <th>{{$t('total')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <template v-for="(row, index) in data_more_details.data">
                                                    <tr :key="'c'+index">
                                                        <td colspan="10">{{row.label}}</td>
                                                    </tr>
                                                    <tr v-for="(row2, index2) in row.details" :key="'c'+getRandom()+index2">
                                                        <td>{{index2 + 1}}</td>
                                                        <td>{{row2.product_name}}</td>
                                                        <td>{{row2.code}}</td>
                                                        <td>{{row2.added_by}}</td>
                                                        <td>{{row2.invoice_code}}</td>
                                                        <td>{{row2.customer_name}}</td>
                                                        <td>{{row2.price_per_unit}}</td>
                                                        <td>{{row2.qty}}</td>
                                                        <td>{{row2.discount_val}}</td>
                                                        <td>{{row2.subtotal_price}}</td>
                                                    </tr>
                                                    <tr :key="'d'+index">
                                                        <td colspan="9">{{$t('daily_brief_report_invoices.total')}}</td>
                                                        <td>{{row.total.subtotal_price}}</td>
                                                    </tr>
                                                </template>

                                                </tbody>
                                                <!-- <tr>
                                                    <td colspan="6"><b>{{$t('total')}}</b></td>
                                                    <th>{{details_total_sum}}</th>
                                                </tr> -->
                                                <tfoot>
                                                <tr v-if="data_more_details.total">
                                                    <td colspan="7">{{$t('total')}}</td>
                                                    <th>{{ data_more_details.total.qty}}</th>
                                                    <th>{{ data_more_details.total.discount_val}}</th>
                                                    <th>{{ data_more_details.total.subtotal_price}}</th>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </b-tab>

                            <!-- <b-tab> -->

                            <!-- </b-tab> -->

                        </b-tabs>
                    </div>
                    <div class="col-12 text-center" v-if="page">
                        <button class="btn btn-warning" @click="loadMore">
                            <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>
                        </button>
                    </div>
                </div>
                <!-- End Filter -->

            </div>
        </div>
      <div class="selector-export" id="selectorExport"></div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import DailyProductSalesChart from '../components/dailyProductSalesChart.vue'
    import TableToExcel from "@linways/table-to-excel";
    import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

    export default {
        name: "index-inventory-transaction-report",
        components: {DailyProductSalesChart},

        data() {
            return {
                mainRoute: '/reports/daily-product-sales-report',
                mainRouteDependency: 'base/dependency',
                suppliers: [],
                items: [],
                inventories: [],
                data: [],
                dataTotal: [],
                brands: [],
                categories: [],
                customers: [],
                employees: [],
                daily_sales: [],
                currencies: [],
                data_more_details: [],
                details_total_sum: [],

                filters: {
                    item_id: null,
                    invoice: null,
                    brand_id: null,
                    customer_id: null,
                    employee_id: null,
                    category_id: null,
                    inventory_id: null,
                    currency_id: null,
                    is_show_draft: null,
                    from_date: null,
                    to_date: null,
                },
                currency_name: null,
                brand_id: null,
                item: null,
                customer: null,
                employee: null,
                filter_by: "daily",
                inventory: null,
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page : 0,
                f_by: 'name',
            }
        },
        computed: {

            json_fields: function () {
                let fields = {};
                fields[this.$t('daily_product_sales.day')] = 'label';
                fields[this.$t('daily_product_sales.qty')] = 'qty';
                fields[this.$t('daily_product_sales.discount')] = 'discount_val';
                fields[this.$t('daily_product_sales.price')] = 'subtotal_price';

                return fields;
            },

            // json_fields_details: function () {
            //     let fields = {};
            //     fields[this.$t('daily_product_sales.number')] = 'label';
            //     fields[this.$t('name')] = 'product_name';
            //     fields[this.$t('daily_product_sales.proudect_code')] = 'code';
            //     fields[this.$t('daily_product_sales.sold_by')] = 'added_by';
            //     fields[this.$t('daily_product_sales.invoice_no')] = 'invoice_code';
            //     fields[this.$t('daily_product_sales.client')] = 'customer_name';
            //     fields[this.$t('daily_product_sales.unit_price')] = 'price_per_unit';
            //     fields[this.$t('daily_product_sales.qty')] = 'qty';
            //     fields[this.$t('daily_product_sales.discount')] = 'discount_val';
            //     fields[this.$t('total')] = 'subtotal_price';
            //     return fields;
            // },


        },
        watch: {
            brand_id: function (val) {
                if (val) {
                    this.filters.brand_id = val.id;
                } else {
                    this.filters.brand_id = null;
                }
            },
            customer: function (val) {
                if (val) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },
            employee: function (val) {
                if (val) {
                    this.filters.employee_id = val.id;
                } else {
                    this.filters.employee_id = null;
                }
            },
            item: function (val) {
                if (val && val.id) {
                    this.filters.item_id = val.id;
                } else {
                    this.filters.item_id = null;
                }
            },
            inventory: function (val) {
                if (val && val.id) {
                    this.filters.inventory_id = val.id;
                } else {
                    this.filters.inventory_id = null;
                }
            }
        },
        methods: {
            convertTableToExcelReport() {
                this.setReportLog('excel', 'daily product sales');

                TableToExcel.convert(document.getElementById("summaryTableDetails"), {
                    name: this.$t('daily_product_sales.daily_product_sales')+'.xlsx',
                    sheet: {
                        name: 'Sheet 1'
                    }
                });
            },
            dataReportDetails() {
                this.setReportLog('excel', 'daily product sales');

                let _footer = {label: this.$t('total') + ` (${this.currency_name})`, total: this.details_total_sum};
                let _data = [];
                this.data_report_details.forEach((row) => {
                    _data.push({
                        label: row.label,
                        product_name: '',
                        code: '',
                        added_by: '',
                        invoice_code: '',
                        customer_name: '',
                        refund: '',
                        date: '',
                        total: '',
                    });
                    row.details.forEach((sub_row) => {
                        _data.push({
                            label: sub_row.label,
                            product_name: sub_row.product_name,
                            code: sub_row.code,
                            added_by: sub_row.added_by,
                            invoice_code: sub_row.invoice_code,
                            refund: sub_row.refund,
                            customer_name: sub_row.customer_name,
                            date: sub_row.date,
                            total: sub_row.total,
                        });
                    });
                    // _data.push({
                    //     label: this.$t('revenue_invoices.total')+` (${this.currency_name})`,
                    //     customer_name: '',
                    //     added_by: '',
                    //     paid : row.total.sum_paid,
                    //     refund : row.total.sum_refund,
                    //     unpaid : row.total.sum_unpaid,
                    //     date: '',
                    //     total: row.total.sum_total,
                    // });
                });
                _data.push(_footer);

                return _data;
            },

            getRandom() {
                return Math.floor(Math.random() * 10000);
            },

            async getAuthUser() {
                await ApiService.get(`/get_auth`).then((response) => {
                    this.user = response.data.data;
                    this.currency_name = response.data.data.currency_name;
                    if (this.user) {
                        this.user_name = this.user.name;
                    }
                });
            },

            selectFilter() {
                this.page = 1;
                this.getDailyProductSalesChart();
                this.getMoreDetails();
                this.getData();
            },

            loadMore() {
                this.page = this.page ? (this.page + 1) : 1;
                this.getData();
                this.getDailyProductSalesChart();
                this.getMoreDetails();
            },

            doFilter() {
                this.page = 1;
                this.getData();
                this.getDailyProductSalesChart();
                this.getMoreDetails();
            },
            resetFilter() {

                this.brand = null,
                    this.item = null,
                    this.customer = null,
                    this.employee = null,
                    this.inventory = null,
                    this.filters.brand_id = null,
                    this.filters.item_id = null,
                    this.filters.invoice = null,
                    this.filters.category_id = null,
                    this.filters.inventory_id = null,
                    this.filters.from_date = null,
                    this.filters.customer_id = null,
                    this.filters.employee_id = null,
                    this.filters.is_show_draft = null,
                    this.filters.to_date = null,
                    this.filters.currency_id = null,
                    this.getData();

            },
            dataReport() {
                this.setReportLog('excel', 'daily product sales');
                return this.data;
            },

            getDailyProductSalesChart() {
                let filter_by = this.filter_by;
                ApiService.get(this.mainRoute + '?data_type=for_chart&filter_by=' + filter_by, {params: {...this.filters,page:(this.page? this.page : 1)}}).then((response) => {
                    this.daily_sales = response.data;
                });
            },
            getData() {
                let filter_by = this.filter_by;
                ApiService.get(this.mainRoute, {params: {...this.filters,page:(this.page? this.page : 1), filter_by}}).then((response) => {
                    this.data = response.data.data.data;
                    this.dataTotal = response.data.data.total;
                }).then().catch((error) => {this.$errorAlert(error)});
            },

            getMoreDetails() {
                let filter_by = this.filter_by;
                ApiService.get(this.mainRoute + '/more-details', {params: {...this.filters,page:(this.page? this.page : 1), filter_by}}).then((response) => {
                    this.data_more_details = response.data.data;
                    let _total_sum = this.$_.sumBy(response.data.data, (row) => {
                        return row.total.sum_total ? parseFloat(row.total.sum_total) : 0;
                    });
                    this.details_total_sum = _total_sum.toFixed(2);
                });
            },

            getItems(f_by = null, filter = null) {
                if (filter && filter.length > 3 && f_by)
                    ApiService.get(this.mainRouteDependency + "/items", {params: {[f_by]: filter}}).then((response) => {
                        this.items = response.data.data;
                    });
            },
            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },
            updateValue(value) {
                this.filters.category_id = value
            },
            loadOptions() {
            },
            getCategories() {
                ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
                    this.categories = response.data.data;
                });
            },
            getCustomers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/customers", {params:{filter: filter}}).then((response) => {
                        this.customers = response.data.data;
                    });
            },
            getEmployees(filter) {
                if(filter && filter.length >= 3)
                ApiService.get(this.mainRouteDependency + "/employees", {params:{name: filter}}).then((response) => {
                    this.employees = response.data.data;
                });
            },
            getBrands() {
                ApiService.get(this.mainRouteDependency + "/brands").then((response) => {
                    this.brands = response.data.data;
                });
            },
            getInventories() {
                ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
                    this.inventories = response.data.data;
                });
            },

            printData(tableId, type) {
                this.setReportLog('pdf', 'daily product sales');
                // let divToPrint = document.getElementById(tableId);
                // let divTitleToPrint = document.getElementById(tableId + 'Title');
                // let newWin = window.open("");
                // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                // setTimeout(() => {
                //     newWin.print();
                //     newWin.close();
                // }, 100)
              exportPDFInnerAction(tableId , type, this.$t('daily_product_sales.daily_product_sales'), this.$t('daily_product_sales.daily_product_sales'));

            },
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.store_reports"),route:'/store/reports'}, {title: this.$t("MENU.daily_product_sales")}]);

            this.getEmployees();
            this.getBrands();
            this.getCategories();
            this.getInventories();
            this.getItems();
            this.getCustomers();
            this.getAuthUser();
            this.getCurrencies();
        },
    };
</script>
<style>
.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;

}
.selector-export{
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
}
</style>